import React, {lazy, Suspense, useState} from 'react';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
const Header = lazy(() => import('./components/header'));
const Footer = lazy(() => import('./components/footer'));
const Home = lazy(() => import('./page/home'));
//about
const Advant = lazy(() => import('./page/about/advantages'));
const Contact = lazy(() => import('./page/about/contact'));
const Aboutus = lazy(() => import('./page/about/about_us'));
const Team = lazy(() => import('./page/about/team'));
const Vision = lazy(() => import('./page/about/vision'));
//service
const Shipping = lazy(() => import('./page/service/shipping'));
const Import = lazy(() => import('./page/service/import'));
const Amazon = lazy(() => import('./page/service/amazon'));
const Airplane = lazy(() => import('./page/service/airport'));
const Quote = lazy(() => import('./page/shipment/list'));
const Detail = lazy(() => import('./page/shipment/detail'));
const Dynamic = lazy(() => import('./page/dynamic/'));
const NotFound = lazy(() => import('./page/nopage'));
const Consult = lazy(() => import('./page/consult'));
const Consultsucc = lazy(() => import('./page/consult_succ'));
const Regist = lazy(() => import('./page/regist'));
const Login = lazy(() => import('./page/login'));
const userInfo = lazy(() => import('./page/user/profile'));
const forget = lazy(() => import('./page/user/forget'));
const terms = lazy(() => import('./page/about/terms'));
const privacy = lazy(() => import('./page/about/privacy'));
const regist_succ = lazy(() => import('./page/regist_succ'));
const bind = lazy(() => import('./page/bind/bind'))
const bind_succ = lazy(() => import('./page/bind/bind_succ'))
const bind_internal = lazy(() => import('./page/bind/bind_internal'))
const bind_internal_succ = lazy(() => import('./page/bind/bind_internal_succ'))
const tracklist = lazy(() => import('./page/bind/tracklist'))

class App extends React.Component{
  render(){
    return (
      <Router hashType="noslash">
      <Suspense fallback={<div id="load"></div>}>
        <Route component={Header} />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/consult" exact component={Consult} />
          <Route path="/consult/succ" component={Consultsucc} />
          <Route path="/about/advantages" component={Advant} />
          <Route path="/about/contact" component={Contact} />
          <Route path="/about/us" component={Aboutus} />
          <Route path="/about/team" component={Team} />
          <Route path="/about/terms" component={terms} />
          <Route path="/about/privacy" component={privacy} />
          <Route path="/about/vision" component={Vision} />

          <Route path="/service/shipping" component={Shipping} />
          <Route path="/service/import" component={Import} />
          <Route path="/service/amazon" component={Amazon} />
          <Route path="/service/airplane" component={Airplane} />

          <Route path="/regist" exact component={Regist} />
          <Route path="/regist/succ" component={regist_succ} />
          <Route path="/login" component={Login} />

          <Route path="/dynamic" component={Dynamic} />

          <Route path="/quote" component={Quote} />
          <Route path="/detail" component={Detail} />
          <Route path="/user" component={userInfo} />

          <Route path="/forget" component={forget} />
          <Route path="/bind" exact component={bind} />
          <Route path="/bind/succ" component={bind_succ} />
          <Route path="/bind_internal" exact component={bind_internal} />
          <Route path="/bind_internal/succ" component={bind_internal_succ} />
          <Route path="/bind/tracklist" component={tracklist} />
          <Route component={NotFound} />
        </Switch>
        <Route component={Footer} />
      </Suspense>
      </Router>
    );
  }
}
export default App;
